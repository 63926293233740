const CalloutWarningIcon = ({ ...props }) => (
	<svg
		{...props}
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM13.092 7.33268C13.1002 7.05098 12.874 6.81818 12.5922 6.81818H11.4097C11.1277 6.81818 10.9015 7.05121 10.9099 7.33305L11.079 13.0149C11.0871 13.2851 11.3084 13.5 11.5788 13.5H12.4273C12.6978 13.5 12.9193 13.2849 12.9271 13.0145L13.092 7.33268ZM11.1581 16.7812C11.3934 17.0133 11.6751 17.1293 12.0032 17.1293C12.2154 17.1293 12.4109 17.0762 12.5899 16.9702C12.7689 16.8608 12.913 16.7166 13.0224 16.5376C13.1351 16.3553 13.1931 16.1548 13.1964 15.9361C13.1931 15.6113 13.0721 15.3329 12.8335 15.1009C12.5949 14.8688 12.3181 14.7528 12.0032 14.7528C11.6751 14.7528 11.3934 14.8688 11.1581 15.1009C10.9228 15.3329 10.8068 15.6113 10.8101 15.9361C10.8068 16.2642 10.9228 16.5459 11.1581 16.7812Z"
			fill="#EFB615"
		/>
	</svg>
)

export default CalloutWarningIcon
